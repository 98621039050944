// footer.js

export function initFooter() {
  const footerHTML = `
        <footer class="footer">
            <div class="footer-content">
                <div class="footer-links">
                        <i class="fa-regular fa-envelope fa-lg" style="color: #74C0FC;"></i>   <a href="#contact">연락 이메일 : deco2040@naver.com</a>
                </div>
                <div class="footer-language">
                    <select id="language-selector" onchange="changeLanguage()">
                        <option value="ko">한국어</option>
                        <option value="ja">日本語</option>
                    </select>
                </div>
                <div class="footer-copyright">
                    &copy; <span id="current-year"></span> 신주쿠 창고.
                </div>
            </div>
        </footer>
    `;

  const style = `
        <style>
            .footer {
                background-color: #2c3e50;
                color: #ecf0f1;
                padding: 15px 0;
                font-family: 'Arial', sans-serif;
            }
            .footer-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: 1200px;
                margin: 0 auto;
                padding: 0 20px;
            }
            .footer-links {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                margin-bottom: 15px;
            }
            .footer-links a {
                color: #bdc3c7;
                text-decoration: none;
                margin: 0 10px;
                font-size: 14px;
                transition: color 0.3s ease;
            }
            .footer-links a:hover {
                color: #ecf0f1;
            }
            .footer-social a:hover {
                color: #ecf0f1;
            }
            .footer-language {
                margin-bottom: 15px;
            }
            .footer-language select {
                background-color: #34495e;
                color: #ecf0f1;
                border: none;
                padding: 5px 10px;
                font-size: 14px;
                border-radius: 5px;
            }
            .footer-copyright {
                text-align: center;
                font-size: 12px;
                color: #95a5a6;
            }
            @media (max-width: 600px) {
                .footer-links {
                    flex-direction: column;
                    align-items: center;
                }
                .footer-links a {
                    margin: 5px 0;
                }
            }
        </style>
    `;

  // 푸터 HTML을 body에 추가
  document.body.insertAdjacentHTML("beforeend", footerHTML);

  // 스타일을 head에 추가
  document.head.insertAdjacentHTML("beforeend", style);

  // Font Awesome 스크립트 추가
  const script = document.createElement("script");
  script.src =
    "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/js/all.min.js";
  script.crossOrigin = "anonymous";
  document.head.appendChild(script);

  // 현재 연도 업데이트
  document.getElementById("current-year").textContent =
    new Date().getFullYear();

  // 언어 변경 함수
  window.changeLanguage = function () {
    const language = document.getElementById("language-selector").value;
    console.log("Selected language:", language);
    // 여기에 언어 변경 로직을 구현합니다.
  };
}
