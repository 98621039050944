import androidChrome192 from "../assets/icons/android-chrome-192x192.png";
import androidChrome512 from "../assets/icons/android-chrome-512x512.png";
import appleTouchIcon from "../assets/icons/apple-touch-icon.png";
import favicon16 from "../assets/icons/favicon-16x16.png";
import favicon32 from "../assets/icons/favicon-32x32.png";
import favicon from "../assets/icons/favicon.ico";

export function injectFavicon() {
  const head = document.querySelector("head");

  const faviconLinks = [
    {
      rel: "icon",
      type: "image/x-icon",
      sizes: "48x48",
      href: favicon,
    },
    {
      rel: "apple-touch-icon",
      type: "image/png",
      sizes: "180x180",
      href: appleTouchIcon,
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "192x192",
      href: androidChrome192,
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "512x512",
      href: androidChrome512,
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: favicon32,
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: favicon16,
    },
  ];

  faviconLinks.forEach(({ rel, href, type, sizes }) => {
    const link = document.createElement("link");
    link.rel = rel;
    link.href = href;
    if (type) link.type = type;
    if (sizes) link.sizes = sizes;
    head.appendChild(link);
  });
}
