// index.js
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { app } from "./firebase.js";

const auth = getAuth(app);

export function init() {
  const loading = document.getElementById("loading");
  const content = document.getElementById("content");
  if (loading && content) {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // 사용자가 로그인한 상태
        console.log("User is sined in:", user.email);
        initializeApp();
      } else {
        // 사용자가 로그인하지 않은 상태
        console.log("No user is signed in");
        window.location.href = "login.html";
      }
      loading.style.display = "none";
      content.classList.remove("hidden");
    });
  }
}

function initializeApp() {
  // 기존의 이벤트 리스너 설정
  const sendToWarehouseButton = document.getElementById("warehouse-sector");
  if (sendToWarehouseButton) {
    sendToWarehouseButton.addEventListener("click", () => {
      window.location.href = "/warehouse/sector.html";
    });
  }
  // 매장 출하 목록 버튼에 대한 이벤트 리스너 추가
  const viewStoreOrdersButton = document.getElementById("store-orders");
  if (viewStoreOrdersButton) {
    viewStoreOrdersButton.addEventListener("click", () => {
      alert("준비중입니다. 곧 서비스할 예정입니다.");
    });
  }

  const viewOrdersButton = document.getElementById("warehouse-order-list");
  if (viewOrdersButton) {
    viewOrdersButton.addEventListener("click", () => {
      window.location.href = "warehouse/orders.html";
    });
  }

  // 장터 매장 출하 버튼에 대한 이벤트 리스너 추가
  const storeSectorButton = document.getElementById("store-sector");
  if (storeSectorButton) {
    storeSectorButton.addEventListener("click", () => {
      alert("준비중입니다. 곧 서비스할 예정입니다.");
    });
  }

  const manageInventoryButton = document.getElementById("manage-inventory");
  if (manageInventoryButton) {
    manageInventoryButton.addEventListener("click", () => {
      window.location.href = "inventory.html";
    });
  }

  // 공지사항 버튼에 대한 이벤트 리스너 추가
  const noticeBoardButton = document.getElementById("notice-board");
  if (noticeBoardButton) {
    noticeBoardButton.addEventListener("click", () => {
      window.location.href = "noticeBoard.html";
    });
  }

  const logoutButton = document.getElementById("logout-button");
  if (logoutButton) {
    logoutButton.addEventListener("click", () => {
      signOut(auth)
        .then(() => {
          // 로그아웃 성공
          window.location.href = "login.html";
        })
        .catch((error) => {
          // 에러 처리
          console.error("로그아웃 중 오류 발생:", error);
        });
    });
  }
}
