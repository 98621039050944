import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeTouchScrollHandler } from "../components/touchScrollHandler.js";

import { injectFavicon } from "../components/favicon.js";
import { initFooter } from "../components/footer.js";

import "../../src/styles/tailwind.css";
import "../assets/styles/reset.css"; // 공통 스타일

import { init } from "../modules/indexButton.js";

// DOM이 로드된 후 이벤트 핸들러를 추가
document.addEventListener("DOMContentLoaded", () => {
  initializeTouchScrollHandler();

  injectFavicon();

  initFooter();

  init();

  displayUserEmail();
});

// 사용자 이메일을 표시하는 함수
function displayUserEmail() {
  const auth = getAuth();
  const userEmailElement = document.getElementById("user-email");

  if (!userEmailElement) {
    console.error("User email element not found");
    return;
  }

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // 사용자가 로그인한 경우
      const userEmail = user.email;
      if (userEmail) {
        // 이메일 주소의 일부를 가리기
        const [username] = userEmail.split("@");
        userEmailElement.textContent = `${username}`;
        userEmailElement.classList.remove("hidden");
      } else {
        userEmailElement.textContent = "이메일 없음";
        userEmailElement.classList.remove("hidden");
      }
    } else {
      // 사용자가 로그인하지 않은 경우
      userEmailElement.textContent = "";
      userEmailElement.classList.add("hidden");
    }
  });
}
