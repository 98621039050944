// src/modules/touchScrollHandler.js

let isScrolling = false;
let scrollTimeout;
const SCROLL_DELAY = 200; // 스크롤 후 터치 이벤트를 무시할 시간 (밀리초)

// 터치 시작 시 스크롤 중인지를 확인하고, 스크롤 중이면 터치 이벤트 무시
export function handleTouchStart(event) {
  if (isScrolling) {
    event.preventDefault();
    return;
  }
}

// 터치 이동 시 스크롤 중임을 설정하고, 일정 시간 이후 스크롤 상태 해제
export function handleTouchMove() {
  isScrolling = true;
  clearTimeout(scrollTimeout);

  scrollTimeout = setTimeout(() => {
    isScrolling = false;
  }, SCROLL_DELAY);
}

// 이벤트를 초기화하는 함수
export function initializeTouchScrollHandler() {
  const buttons = document.querySelectorAll("button");

  buttons.forEach((button) => {
    button.addEventListener("touchstart", handleTouchStart, { passive: false });
  });

  document.addEventListener("touchmove", handleTouchMove, { passive: true });
}
